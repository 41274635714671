import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { API, Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import SyntaxHighlighter from 'react-syntax-highlighter';
import Markdown from 'react-markdown'


import {
  FluentProvider,
  webLightTheme,
  makeStyles,
  useId,
  tokens,
  Button,
  Label,
  Drawer, DrawerHeader, DrawerHeaderTitle, OverlayDrawer, DrawerProps, DrawerBody,
  Toaster, useToastController, ToastTitle, Toast, ToastIntent,
  Textarea, TextareaProps, Link, Dialog, DialogTrigger, DialogSurface, DialogBody, DialogTitle, DialogContent, DialogActions, Spinner, Select, SelectOnChangeData
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";


const useStyles = makeStyles({
  base: {
    display: "flex",
    flexDirection: "column",
    "& > label": {
      marginBottom: tokens.spacingVerticalMNudge,
    },
  },
});


interface AiMessage{
  me?: boolean;
  query_id?: string;
  message?: string;
  image?: string;
  framework?: string;
  date?: Date;
}


Amplify.configure({
  Auth: {
      region: awsExports.REGION,
      userPoolId: awsExports.USER_POOL_ID,
      userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID
  }
})

const API_BASE_TASKWEAVER = "https://wa-techlab-opendata-api-1.azurewebsites.net"
const API_BASE_AZUREOAI = "https://wa-techlab-opendata-api-2.azurewebsites.net"

// const API_BASE_TASKWEAVER = "http://127.0.0.1:5000"
// const API_BASE_AZUREOAI = "http://127.0.0.1:5000"

//const API_BASE = "https://wa-techlab-opendata-api-1.azurewebsites.net"
//const API_BASE = "http://127.0.0.1:5000"

const SET_CUSTOM_INSTRUCTION = 'customInstruction';
const SET_CUSTOM_PROMPT = 'customPrompt';


const API_KEY = "^*mDyBc1Ear7wR!r^o^@3b*Ai7^a9X";

function App() {
  const textareaId = useId("textarea");
  const customInstructionId = useId("textarea-ci");
  const customPromptId = useId("textarea-cp");
  const toasterId = useId("toaster");
  const styles = useStyles();

  const [query, setQuery] = useState("");
  const [customInstruction, setCustomInstruction] = useState(localStorage.getItem(SET_CUSTOM_INSTRUCTION) || "");
  const [customPrompt, setCustomPrompt] = useState(localStorage.getItem(SET_CUSTOM_PROMPT) || "");
  const [messages, setMessages] = useState<AiMessage[]>([]);
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dataset, setDataset] = useState("dpi_lac_2021");
  const [framework, setFramework] = useState("taskweaver");
  const [isOpenSettings, setIsOpenSettings] = useState(false);
  const {dispatchToast} = useToastController(toasterId)

  // useEffect(()=>{
  //   //TODO: check local storage for settings.
  // }, [])

  const askApi = async () =>{
    if(query!==""){

      let newMessages = [
        ...messages,
        { me: true, message: query, date: new Date()}
      ]

      setMessages(newMessages);
      setIsLoading(true);

      const request = await fetch(`${framework==="taskweaver"?API_BASE_TASKWEAVER:API_BASE_AZUREOAI}/query`, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "x-api-key": API_KEY,          
        },
        body: JSON.stringify({
          "dataset": dataset,
          "query": query,
          "framework": framework,
          "customInstruction": customInstruction && customInstruction.length>0?customInstruction:null,
          "customPrompt": customPrompt && customPrompt.length>0?customPrompt:null
        })
      })
      if(request.status==200 || request.status ==201){
        const message = await request.json();

        if(framework=="taskweaver"){
          let taskweaverMessages: any[] = [];
          if (Array.isArray(message.msg.post_list)){
            message.msg.post_list.forEach((post: any)=>{
              console.log(post);
              if(post.send_from!=="User"){
                taskweaverMessages.push({me: false, message: post.message, query_id: post.query_id, framework: framework, date: new Date()});
              }
            });
          }
          setMessages([
            ...newMessages,
            ...taskweaverMessages
          ]);
        }
        else{
          setMessages([
            ...newMessages,
            { me: false, message: message.msg ||undefined, image: message.img_string || undefined, query_id: message.query_id, framework: framework, date: new Date()}
          ]);
          
        }
        setQuery("");
      }
      setIsLoading(false);
    }
  }

  const onCodeOpen = async (event: any, data: any, query_id?: string, message_framework?: string) =>{
    if(data.open){
      if(query_id!==""){
        setIsLoading(true);
        const request = await fetch(`${framework==="taskweaver"?API_BASE_TASKWEAVER:API_BASE_AZUREOAI}/logs`, {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "x-api-key": API_KEY,          
          },
          body: JSON.stringify({
            "query_id": query_id,
          })
        })
        if(request.status==200 || request.status ==201){
          const message = await request.json();
          setCode(message.text);
        }
        setIsLoading(false);
      }
      
    }
    else{
      setCode("");
    }
  }

  const onDatasetChange = (event: React.ChangeEvent<HTMLSelectElement>, data: SelectOnChangeData) =>{
    setDataset(data.value);
  }

  const onFrameworkChange = (event: React.ChangeEvent<HTMLSelectElement>, data: SelectOnChangeData) =>{
    setFramework(data.value);
  }

  const saveSettings = ()=>{
    localStorage.setItem(SET_CUSTOM_INSTRUCTION, customInstruction);
    localStorage.setItem(SET_CUSTOM_PROMPT, customPrompt);
    // TODO: add a popup with success message.
    notify("Settings were saved.")
  }

  const notify = (message: string) =>{
    dispatchToast(
      <Toast>
        <ToastTitle>{message}</ToastTitle>
      </Toast>, 
      {intent: "success"}
    )
  }

  const selectDataset = useId();
  const selectFramework = useId();

  return (
    <FluentProvider theme={webLightTheme}>
      <Authenticator >
        {({ signOut, user }) => (
          <div className='app'>
            <header>
              <div className='container'>
                <div>
                  <h1>Open Data Hub Buddy</h1>
                </div>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems: 'center'}}>
                  <div>
                    <p>Welcome {user?.username} </p>
                  </div>
                  <div>
                    <Button onClick={()=>setIsOpenSettings(true)}>Settings</Button>
                    <Button onClick={signOut} appearance='subtle'>Sign out</Button>
                  </div>
                </div>
                
                <div>
                  <>
                    <Label htmlFor={selectDataset}>Dataset</Label>
                    <Select id={selectDataset} onChange={onDatasetChange}>
                      <option value={"dpi_lac_2021"}>Political Institutions (LAC-Latest)</option>
                      <option value={"dpi_lac_all_years"}>Political Institutions (LAC-All Years)</option>
                      <option value={"dpi_world_all_years"}>Political Institutions (World-All Years)</option>
                      <option value={"lmw_2020"}>Latin Macro Watch (2020)</option>
                      <option value={"lmw_all_years_wide_format"}>Latin Macro Watch (Wide)</option>
                      <option value={"lmw_all_years_long_format"}>Latin Macro Watch (Long)</option>
                      <option value={"lmw_economic_activity_all_years_wide_format"}>Latin Macro Watch (Limited fields)</option>
                    </Select>
                  </>
                  <>
                    <Label htmlFor={selectFramework}>Framework</Label>
                    <Select id={selectFramework} onChange={onFrameworkChange}>
                      <option value={"taskweaver"}>TaskWeaver</option>
                      <option value={"pandas"}>PandasAI</option>
                    </Select>
                  </>
                </div>                
              </div>
            </header>
            <main>
              <section className='full'>
                <div className='container'>                  
                  {/* Message Area */}
                  <div>
                    {messages.map((message, index)=>{
                      let nowDate = message.date? message.date: new Date();
                      let bubbleStyle = message.me?"MessageBubble MessageBubbleMe":"MessageBubble";
                      return(
                        <div className={message.me?`MessageMe`:`MessageSystem`} key={index} >
                          <div className={bubbleStyle}>
                            {message.image?
                              <img src={`data:image/png;base64, ${message.image}`} width={"100%"}/>
                              :
                              <Markdown>
                                {message.message}
                              </Markdown>
                            }
                            {!message.me && message.framework!=="taskweaver" &&
                            <Dialog onOpenChange={(event, data)=>onCodeOpen(event, data, message.query_id, message.framework)}>
                              <DialogTrigger>
                                <Link>Show Code</Link>
                              </DialogTrigger>
                              <DialogSurface>
                                <DialogBody>
                                  <DialogTitle>Code</DialogTitle>
                                  <DialogContent>
                                  <SyntaxHighlighter language="python">
                                    {code}
                                  </SyntaxHighlighter>
                                  </DialogContent>
                                </DialogBody>
                                <DialogActions>
                                  <DialogTrigger>
                                    <Button appearance='secondary'>Close</Button>
                                  </DialogTrigger>
                                </DialogActions>
                              </DialogSurface>
                            </Dialog>
                            }
                            <div><p style={{fontSize: '0.5em', font:'small-caption'}}>{message.framework} &bull; {`${nowDate.toLocaleDateString()} ${nowDate.toLocaleTimeString()}`}</p></div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  
                </div>
                {/* Settings Drawer         */}
                <OverlayDrawer
                  position='end'
                  open={isOpenSettings}
                  onOpenChange={(_, {open})=> setIsOpenSettings(open)}
                >
                  <DrawerHeader>
                    <DrawerHeaderTitle
                      action={
                        <Button
                          appearance='subtle'
                          aria-label='close'
                          icon={<Dismiss24Regular/>}
                          onClick={()=>setIsOpenSettings(false)} 
                        />
                      }
                    ></DrawerHeaderTitle>
                  </DrawerHeader>
                  <DrawerBody>
                    <p>Pandas AI Settings</p>
                    <div>
                      <Label htmlFor={customInstructionId}>Custom Instruction</Label>
                      <br/>              
                      <Textarea 
                        id={customInstructionId} 
                        value={customInstruction} 
                        onChange={(event, data)=>setCustomInstruction(data.value)}
                        style={{width:'100%'}}
                        resize='vertical'
                      /> 
                    </div>
                    <br/>
                    <div>
                      <Label htmlFor={customPromptId}>Custom Prompt</Label>
                      <br/>              
                      <Textarea 
                        id={customPromptId} 
                        value={customPrompt} 
                        onChange={(event, data)=>setCustomPrompt(data.value)}
                        style={{width:'100%'}}
                        resize='vertical'
                      /> 
                    </div>
                    <br/>
                    <Button appearance='primary' onClick={()=>saveSettings()}>Save</Button>
                  </DrawerBody>
                </OverlayDrawer>
                <Toaster toasterId={toasterId} />
              </section>
            </main>
            <footer>
              <div className='container'>
                {/* Loader */}
                <div hidden={!isLoading}>
                  <Spinner label={"Getting things ready..."} size="small"/>
                </div>
                <br/><br/>
                <Label htmlFor={textareaId}>Ask your question</Label>
                <br/>              
                <Textarea 
                  id={textareaId} 
                  value={query} 
                  onChange={(event, data)=>setQuery(data.value)}
                  style={{width:'400px', marginRight: '1em'}}
                />              
                <Button appearance='primary' onClick={askApi}>Ask</Button>
              </div>
            </footer>
          </div>
        )}        
      </Authenticator>
    </FluentProvider>
  );
}

export default App;